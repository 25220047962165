import React, { useState } from "react"
import { navigate } from "gatsby"
import useAuth from "../../hooks/useAuth"

const Login = ({ redirect }) => {
  const { login } = useAuth()
  const [identifier, setIdentifier] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      await login({ identifier, password })
      navigate(redirect)
    } catch (e) {
      console.log("Error occurred during authentication")
      const {
        response: {
          data: {
            message: [
              {
                messages: [error],
              },
            ],
          },
        },
      } = e
      const { message: msg } = error
      setError(msg)
    }
  }

  return (
    <div className="container max-width-adaptive-sm bg-contrast-lower padding-md margin-y-md">
      <form method="post" onSubmit={handleSubmit}>
        <div className="text-component text-center margin-bottom-sm">
          <h1>Parents & Staff Area Login</h1>
          <p>
            To access this area, you need to log in.<br></br>
            Please <a href="/get-in-touch">get in touch</a> if you haven't
            received your login details.
          </p>
        </div>
        <div className="margin-bottom-sm">
          <label className="form-label margin-bottom-xxxs" htmlFor="username">
            Username
          </label>
          <input
            className="form-control width-100%"
            id="username"
            type="text"
            name="username"
            onChange={e => setIdentifier(e.target.value)}
          />
        </div>

        <div className="margin-bottom-sm">
          <label className="form-label margin-bottom-xxxs" htmlFor="password">
            Password
          </label>
          <input
            className="form-control width-100%"
            id="password"
            type="password"
            name="password"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="margin-bottom-sm">
          <input
            className="btn btn--primary btn--md width-100%"
            type="submit"
            value="Log In"
          />
        </div>
      </form>
      {error.length > 1 && (
        <>
          <div
            className="alert alert--error alert--is-visible padding-sm radius-md js-alert"
            role="alert"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <svg
                  className="icon icon--sm alert__icon margin-right-xxs"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M13.645,5L13,14h-2l-0.608-9 H13.645z M12,20c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C14,19.105,13.105,20,12,20z"></path>
                </svg>

                <p className="text-sm">
                  <strong>Error:</strong> {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Login
