import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Login from "../components/protected/Login"

const LoginPage = ({ location }) => {
  const { state: routeState } = location
  const redirect = !routeState
    ? "/protected"
    : routeState.redirect === "protected"
    ? "/protected"
    : `/protected/${routeState.redirect}`

  return (
    <Layout>
      <Seo
        title="Login Page | Vacational Studies"
        description="Login here to access the protected area of Vacational Studies"
      />
      <Login redirect={redirect} />
    </Layout>
  )
}

export default LoginPage
